.crud{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;


    .spinner-overlay{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.644);
        z-index: 7;
        top: 0;
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .spinner{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          width: 90px;
          height: 90px;
          animation: spin 3.5s linear infinite; 
          border: none;
          img{
            width: 100%;
            height: 100%;
            border-radius: 60px;
      
            border: none;
          }
        }
      }

    form{
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    span{
        font-size: 1.5rem;margin-bottom: 25px;
        font-weight: bold;
        border-bottom: 3px solid black;
        line-height: 1;
    }
    select{
        width: 210px;
        height: 30px;
        margin-bottom: 5px;
        font-size: 1.1rem;
        text-align: center;
    }
    input{
        width: 200px;
        height: 30px;
        margin-top: 5px;
        font-size: 1.1rem;
        padding-left: 5px;
        text-align: center;
    }
    textarea{
        width: 200px;
        padding-left: 4px;
        margin-top: 5px;
        font-size: 1rem;
        height: 80px;
    }
    button{
        width: 90px;
        height: 35px;
        font-size: 1.2rem;
        border: none;
        border-radius: 20px;
        background-color: rgb(13, 187, 36);
        color: rgb(249, 249, 249);
        text-shadow: 1px  1.5px 2px  rgb(2, 45, 22);
        box-shadow: 1px 1.5px 2px 2.5px  rgba(84, 83, 83, 0.581);
        margin-top: 20px;
        &:active{
            font-size: 1.1rem;
            width: 89px;
            height: 34px;
        }
    }
    }

    select{
        width: 220px;
        height: 30px;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        margin: 10px;
    }
    .vista{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        width: 350px;
        border:1px solid gray;
        padding: 10px 10px;

   
        .editar{
            border: none;
            box-shadow: none;
            margin-top: -1px;
          .edit-icon{
            color: black;
            font-size: 1.3rem;
          }
        }
        h4{
            margin-left: 175px;
           margin-bottom: -27px;
           z-index: 1;
           border-radius: 30px;
           background-color: rgb(255, 255, 255);
           padding: 2px;
        }
        img{
            width: 200px;
            height:auto ;
        }
        h3{
            margin: 8px 0;
        }
       p{
            white-space: pre-wrap;
       }
        button{
            margin-top: 10px;
            margin-left: 250px;
            font-size: 1rem;
            border: 1px solid red;
            border-radius: 10px;
            background-color: rgb(255, 255, 255);
            color: red;
            padding: 4px;
            box-shadow: 1px 1.5px 2px 2.5px  rgba(84, 83, 83, 0.33);
            cursor: pointer;
        }
    }
}

@media (max-width:390px){
    .crud{
        .vista{
            width: 90%;

            button{
                margin-left: 230px;
            }
        }
    }
}