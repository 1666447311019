.Niveles{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 12%;
    text-shadow: 2px 2px 5px rgba(165, 164, 164, 0.508);

    .spinner-overlay{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.941);
        z-index: 7;
        top: 0;
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .spinner{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          width: 90px;
          height: 90px;
          animation: spin 3.5s linear infinite; 
          border: none;
          img{
            width: 100%;
            height: 100%;
            border-radius: 60px;
      
            border: none;
          }
        }
      }

       h4{
        width: 100%; 
    font-size: 1.3rem;
    line-height: 1.5;
    text-align: center;
    padding: 0.5rem 0rem;
    margin-bottom: 20px;
    
     p{
            font-size: 1.3rem;
            font-weight: 500;
            padding: 0 1rem;

        }
        
        h3{  
        margin: 4%;
         padding: 30px 10px;

       strong{
        font-family: 'Jost', sans-serif;
        font-size: 3rem;
        line-height: 1.1;
        text-shadow: 2px 2px 5px rgba(94, 94, 94, 0.513);
       }
       }
    }
       
        .punto{
         color: rgb(41, 41, 41);
        }

        .imgNiveles{
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(240, 173, 65, 0.88);
            margin-left: -0.4%;
            text-shadow: 2px 2px 5px rgba(128, 127, 127, 0.221);

            img{
            width: 254px;
            height: 170px;
            object-fit: cover;
            padding: 32px 30px;
        }  
        .divItem{
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 234, 148, 0.777);
            width: 254px;
            height: 120px;
            margin: -36px 30px 10px auto;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px ;
            font-family: 'Jost', sans-serif;
            font-size: 1.6rem;
             line-height: 1.5;
            }
        }
        .perfil{
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            margin-left: -1.1%;
            h3{
                padding-top: 3rem;
                padding-bottom: 3rem;
                font-family: 'Jost', sans-serif;
                font-size: 2.3rem;
                line-height: 1.1;
                text-align: center;
            }
            section{
                padding-top: 2rem;
                padding-bottom: 5rem;
            
      
                ul{
                    padding-left: 2rem;
                    padding-right: 1.4rem;
                    font-size: 1.4rem;
                    font-family: 'Jost', sans-serif;
                    li{
                        margin-bottom: 1rem;
                        list-style: none;
                        position: relative;
                        padding-left: 1rem;
                        padding-top: 16px;
                      .ok{
                        position: absolute;
                        left: -18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: aliceblue;
                        border: 3px solid #6592e6;
                        color: #6592e6;
                        width: 17px;
                        height: 17px;
                        border-radius: 50%;
                        margin-top: 0.5%;
                      }                      
                    }
                }
            }
        }
    }
    
    @media (max-width:963px){
        .Niveles{
        h4{
            font-size: 1rem;
            font-weight: 200;
            p{
            font-size: 1.3rem;

            }
            h3{
                strong{
                    font-size: 2.5rem;
                }
            }
        }
        .imgNiveles{
            flex-flow: column wrap;
            
        img{
            align-content: center;
            width:100%;
        }
        .divItem{
        width: 100%;
        margin-left: 9.5%;
        }
        }
    }
    }

    @media (max-width:700px){
        .Niveles{
            margin-top: 15%;
        h4{
            font-size: 1rem;
            font-weight: 200;
          p{
            font-size: 1.1rem;
            
            
          }
            h3{
                margin-top: 7%;
                padding: 0 0.1rem;
                strong{
                    font-size: 2rem;
                }
            }
        }
        .imgNiveles{
            flex-flow: column wrap;
            margin-top: 60px;
        img{
            align-content: center;
            width:80%;
        }
        .divItem{
        width: 80%;
        margin-left: 9.5%;

        }
        }
        .perfil{
            h3{
               font-size: 1.5rem;
            }
        
        }
        section{
            
        ul{
            margin-top: -5%;
            
            li{
            font-size: 1rem;
           
        }
        
        }
    }
    }
    
    }


       

