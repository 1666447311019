.Nosotros{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 12%;
 

    .spinner-overlay{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.941);
        z-index: 7;
        top: 0;
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .spinner{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          width: 90px;
          height: 90px;
          animation: spin 3.5s linear infinite; 
          border: none;
          img{
            width: 100%;
            height: 100%;
            border-radius: 60px;
      
            border: none;
          }
        }
      }


    h1{
        padding:2rem 0.4rem;
        font-weight:bold;
        font-size: 3.04rem;
        color: #e9c113;
        text-shadow: 1px 1px 2px rgba(112, 110, 110, 0.484);
        @keyframes moverDeAdentroHaciaAfuer{
            0% {
              transform: scale(0);
              opacity: 0;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }
          animation: moverDeAdentroHaciaAfuer 1s ease-out;
    }
    .parrafo1{
        background-color: rgba(251, 251, 251, 0.645);
    
        margin-top: 10px;
        padding: 0 2rem;
       
    p{
        min-height: 80vh;
        padding-top: 2.7rem;
        padding-bottom: 3rem;
        box-sizing:border-box;
        font-size: 1.2rem;
        line-height: 1.5;
        padding-right: 2rem;
        text-shadow: 1px 1px 2px rgb(236, 236, 236);
        font-family: 'Jost', sans-serif;
}
}
    ul{
        text-align: left;   
        padding-left: 2.5rem;
        font-size: 1.2rem;
        line-height: 1.5;
        box-sizing: border-box;
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 30px;
        background-color: rgba(251, 251, 251, 0.645);
        margin-top:10px;
        position: relative;
        padding-right: 3.9rem;
        margin-bottom: 25px;

        li {
            font-size: 1.1rem;
            p{
                font-family: 'Jost', sans-serif;
            }
            strong{
            font-size: 1.3rem;    
            color: rgb(0, 0, 0);
        }
        }
        li::marker{
            color: rgb(95, 95, 255);
            font-size: 20px;
        }
}
.objetivos{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-left: -1.1%;
    h3{
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-family: 'Jost', sans-serif;
        font-size: 2.3rem;
        line-height: 1.1;
        text-align: center;
        text-decoration: underline;
    }
    section{
        padding-top: 2rem;
        padding-bottom: 5rem;
    

        ul{
            padding-left: 2rem;
            padding-right: 1.4rem;
            font-size: 1.4rem;
            font-family: 'Jost', sans-serif;
            li{
                margin-bottom: 1rem;
                list-style: none;
                position: relative;
                padding-left: 1rem;
                padding-top: 16px;
              .ok{
                position: absolute;
                left: -18px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: aliceblue;
                border: 3px solid #6592e6;
                color: #6592e6;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                margin-top: 0.5%;
              }                      
            }
        }
    }
}
    }



@media (max-width:768px){
    .Nosotros{
    .objetivos{
   
        h3{
           font-size: 1.8rem;
        }
}
}
}

@media (max-width:630px){
.Nosotros{

    h1{
        font-size: 2.4rem;
    }

.parrafo1{
    margin-top: -7%;
    padding: 0 1.1rem;
    p{
        font-size: 1rem;
        padding-left: 0.8rem;
        padding-right: 1rem;
    }
}
ul{
    padding-right: 0.7rem;
    margin-top: 0;
    
    li{
        font-size: 1rem;
        padding-right: 0.4rem;
        strong{
            font-size: 1.05rem;    
        }
      
    }
}
.objetivos{
   
        h3{
           font-size: 1.5rem;
        }
        section{
            
        ul{
            margin-top: -9%;
            
            li{
            font-size: 1rem;
            margin-top: -4%;
           
        }
        
        }
    }
}
}
}

@media (max-width:480px){
    .Nosotros{
        margin-top: 13%;
  
        h1{
            margin-top:3% ;
            font-size: 2.1rem;
        }
    
    .parrafo1{
        margin-top: -7%;
        padding: 0 0.8rem;
        p{
            font-size: 0.9rem;
            
        }
    }
    ul{ 
        padding-right: 0.6rem;
        margin-top: 10px;
        li{
            font-size: 0.9rem;
            padding-right: 0.4rem;
        }
    }
    }
    }
    