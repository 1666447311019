.contacto{
    margin-top: 9%;
}
.login{
    position: absolute;
    margin-top: -32px;
    background-color: transparent;
    border: none;
    padding-left: 8px;
    cursor: pointer;
    .login-icon{
        font-size: 2rem;
        color: #000000b1;
    }
}
.titulo{
    padding-top: 50px;
    margin-bottom: -180px;
    text-align: center;
    font-size: 1.6rem;
}    
form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    input{
        width: 200px;
        height: 30px;
        margin-bottom: 25px;
        padding-left: 3px;
        font-size: 1rem;
    }
    button{
        width: 90px;
        height: 35px;
        font-size: 1.2rem;
        border: none;
        border-radius: 20px;
        background-color: rgb(13, 187, 36);
        color: rgb(240, 240, 240);
        text-shadow: 1px  1.5px 2px  rgb(2, 45, 22);
        box-shadow: 1px 1.5px 2px 2.5px  rgba(84, 83, 83, 0.581);
        margin-top: 10px;
        letter-spacing: 0.6px;
        cursor: pointer;
        &:active{
            font-size: 1.1rem;
            width: 89px;
            height: 34px;
        }
    }
}
.section1{
background-image: url('../../../public/assets/background5.jpg');
background-repeat: no-repeat;
background-size: 100%;
width: 100%;
height: 50%;

.padre1{
    text-align:center ;
    font-family:  sans-serif;
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 700;
    background-color: #e0742cb3;
    h1{
        color: #ffffff;
        font-size: 3.8rem;
        line-height: 1.2;
        padding: 2rem 0;
        text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
    }
    p{
        color: #fafafa;
        font-family: 'Jost', sans-serif;
        font-size: 1.4rem;
        line-height: 1.5;
        word-break: break-word;
        word-wrap: break-word;
        font-weight: 400;
        padding: 0.8rem;
        text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
        margin-top: -30px;
}
}
    }
    .padre2{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        background-color: #ee843e0b;  
        .hijo{
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            padding: 3.8rem;
            color: aliceblue;
            span{
                font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size:2rem;
                padding-top: 0.5rem;
                color: aliceblue;
                font-weight: 500;
                text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
                line-height: normal;
                
            }
            .icono1{
                font-size: 7rem;
                color: hsl(37, 98%, 51%);
                background-size: cover; /* Ajusta el tamaño de la imagen */
                box-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
                border-radius: 10px;
                background-color: hwb(60 99% 0%);
                
            }
            .icono2{
                font-size: 7rem;
                color: hsl(37, 98%, 51%);
                box-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
                border-radius: 10px; 
                background-color: hwb(60 99% 0%);
                
            }
            .icono3{
                font-size: 7rem;
                color: hsl(37, 98%, 51%);
                box-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
                border-radius: 10px;
                background-color: hwb(60 99% 0%);
            }
            .icono4{
                font-size: 7rem;
                color: hsl(37, 98%, 51%);
                box-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
                border-radius: 10px;
                margin-bottom: -3%;
                background-color: hwb(60 99% 0%);
                
                
            }
        }
   }
   .section2{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .cont{
        font-size: 2rem;
        line-height: 1.1;
        margin-top: 50px;

    

    }
    .padre3{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        background-image: url('../../../public/assets/background6.jpg');
        width: 100%;
        height: 600px;
        margin-top: 60px;
        .tel-email{
            padding: 0 1rem;
            margin-right: 50px;
            line-height: 1.7;
            .tel{
                box-shadow: 4px 4px 2px #d0cfcf, -2px -2px 2px #cecaca;
                padding: 1rem;
                padding-bottom: 2rem;
                background: #ffffff;
                border-radius: 2px;
               
             
                h6{
                    font-weight: bold;
                    font-family: 'Jost', sans-serif;
                     font-size: 1.6rem;
                     line-height: 1.5;
                     font-weight: bold;
                     text-align: center;
                }
                p{
                    font-family: 'Jost', sans-serif;
                    font-size: 1.6rem;
                    letter-spacing: 0.5px;
                 
                    
                 }
                }
                .email{
                    box-shadow: 4px 4px 2px #d0cfcf, -2px -2px 2px #cecaca;
                    margin-top: 10px;
                    border-radius: 2px;
                    background: #ffffff;
                    h6{
                        font-weight: bold;
                        font-family: 'Jost', sans-serif;
                         font-size: 1.6rem;
                         line-height: 1.5;
                         font-weight: bold;
                         text-align: center;
                    }
                    p{
                        font-family: 'Jost', sans-serif;
                        font-size: 1.6rem;
                        padding: 1rem;
                       
                     }
                }
            }

        }
        .mapa{
            height: 430px;
            width:400px;
            box-shadow: 4px 4px 2px #d0cfcf, -2px -2px 2px #cecaca;
            iframe{
                width: 100%;
                height: 100%;
                border: none;
            }
        }

    }

    
    @media (max-width: 959px){
        .section2{
            width:auto ;
            h3{
             font-size: 2rem;
             margin: 390px 40% -60px auto;
             position: relative;
            }
            .padre3{
             width: 100%;
             .tel-email{
                padding: 0.1rem;
                width: 40%;
                margin-right: 20px;
                .tel{
                 h6{
                      font-size: 1.6rem;
                      line-height: 1.5;
                      text-align: center;
                 }
                 p{
                     font-size: 1.3rem; 
                  }
                }
                .email{
                 h6{
                      font-size: 1.4rem;
                      line-height: 1.4;
                      font-weight: bold;
                      text-align: center;
                 }
                 p{
                     font-size: 1.2rem;
                     padding: 1rem;
                    
                  }
             }
            }
             }
             .mapa{
                 height: 370px;
                 width:310px;
             
             }
         }
     
    }

    @media (min-width: 932px){
        .section2{
            width:auto ;
            background-repeat:repeat ;
        }
    }
    @media (max-width: 932px){
       .section1{
        height: 100%;
        .padre1{
            line-height:1.5;
            font-weight: 600;
        
            h1{
                font-size: 3rem;
                line-height: 1.1;
                padding: 2rem 0;
            }
            p{
                color: #fafafa;
                font-family: 'Jost', sans-serif;
                font-size: 1.5rem;
                line-height: 1.7;
                word-break: break-word;
                word-wrap: break-word;
                font-weight: 400;
                padding: 0.6rem;
        }
        .padre2{
            line-height: 2;
            .hijo{
                padding: 3.4rem;
                color: aliceblue;
                span{
                    font-size:1.8rem;
                }
                .icono1{
                    font-size: 6rem;
                    
                    
                }
                .icono2{
                    font-size: 6rem;
                 
                    
                }
                .icono3{
                    font-size: 6rem;
                    
                }
                .icono4{
                    font-size: 6rem;
                }
            }
        }
        }
    }
        .section2{
       width:auto ;
       h3{
        font-size: 2rem;
        margin: 40px 40% -60px auto;
        position: relative;
       }
       .padre3{
        width: 100%;
        .tel-email{
           padding: 0.1rem;
           width: 40%;
           margin-right: 20px;
           .tel{
            h6{
                 font-size: 1.6rem;
                 line-height: 1.5;
                 text-align: center;
            }
            p{
                font-size: 1.3rem; 
             }
           }
           .email{
            h6{
                 font-size: 1.4rem;
                 line-height: 1.4;
                 font-weight: bold;
                 text-align: center;
            }
            p{
                font-size: 1.2rem;
                padding: 1rem;
               
             }
        }
       }
        }
        .mapa{
            height: 370px;
            width:310px;
        
        }
    }

    }
    @media (max-width: 828px){
        .section1{
            height: 100%;
            .padre1{
                line-height:1.5;
                font-weight: 600;
              
                h1{
                    font-size: 2.2rem;
                    line-height: 1.1;
                    padding: 2.5rem 0;
                }
                p{
                    color: #fafafa;
                    font-family: 'Jost', sans-serif;
                    font-size: 1.5rem;
                    line-height: 1;
                    word-break: break-word;
                    word-wrap: break-word;
                    font-weight: 400;
                    padding: 0.4rem;
            }
            .padre2{
                flex-flow: column wrap;
                line-height: 1.6;
                margin-top: 5%;
                .hijo{
                    padding: 2rem 0;
                    color: aliceblue;
                    span{
                        font-size:1.4rem;
                    }
                    .icono1{
                        font-size: 5rem;
                  
                    }
                    .icono2{
                        font-size: 5rem;
                 
                    }
                    .icono3{
                        font-size: 5rem;
                    }
                    .icono4{
                        font-size: 5rem;
                    }
    
                }
            }
            }
        }


        .section2{
            h3{
             font-size: 2rem;
             margin: 40px 40% -100px auto;
            
            }
            .padre3{
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                align-items: center;
                width:auto ;
                height: 850px;
                width: 100%;
                
             .tel-email{
                display: flex;
                flex-flow: column ;
                justify-content: center;
                align-items:initial;
                padding: 0.1rem;
                width: 74%;
                margin-right: 20px;
                margin-bottom: 180px;
                .tel{
                 h6{
                      font-size: 1.7rem;
                      line-height: 1.5;
                      text-align: center;
                 }
                 p{
                     font-size: 1.5rem; 
                     text-align: center;
                  }
                }
                .email{
                 h6{
                      font-size: 1.7rem;
                      line-height: 1.6;
                      font-weight: bold;
                      text-align: center;
                 }
                 p{
                     font-size: 1.6rem;
                     padding: 1rem;
                     text-align: center;
                    
                  }
             }
            }
             }
             .mapa{
                 height:25%;
                 width:74%;
                 position: absolute;
                 margin: 65% 2% -50px auto;
                 
             
             }
         }
    }

    @media (max-width: 664px){
        .contacto{
            margin-top: 12%;
        }
        .section1{
            height: 100%;
            .padre1{
                background-color: #e0742cd1;
                h1{
                    font-size: 1.8rem;
                    line-height: 1.1;
                    padding: 3.4rem 0rem;
                }
                p{
                    color: #fafafa;
                    font-family: 'Jost', sans-serif;
                    font-size: 1.2rem;
                    line-height: 1;
                    word-break: break-word;
                    word-wrap: break-word;
                    font-weight: 400;
                    padding: 0.2rem;
            }
            .padre2{
                .hijo{
                    padding: 0.8rem;
                    color: aliceblue;
                    span{
                        font-size:1.2rem;
                        font-weight: 400;
                    }
                    .icono1{
                        font-size: 4rem;
                
                        
                    }
                    .icono2{
                        font-size: 4rem;
                        
                        
                    }
                    .icono3{
                        font-size: 4rem;
                    }
                    .icono4{
                        font-size: 4.3rem;
                    }
    
                }
            }
            }
        }


        .section2{
            h3{
             font-size: 1.8rem;
             margin: auto 40% -100px auto;
            
            }
            .padre3{
                display: flex;
                flex-flow: column wrap;
                height: 850px;
                width: 100%;     
                margin-top: 0;
             .tel-email{
                display: flex;
                flex-flow: column ;
                justify-content: center;
                align-items:initial;
                width: 74%;
                margin-right: 20px;
                margin-bottom: 180px;
                .tel{
                 h6{
                      font-size: 1.5rem;
                      line-height: 1.4;
                      text-align: start;
                 }
                 p{
                     font-size: 1.3rem; 
                     text-align: start;
                     font-weight: 400;

                     
                  }
                }
                .email{
                    margin-top: 20px;
                 h6{
                      font-size: 1.5rem;
                      line-height: 1.4;
                      font-weight: bold;
                      text-align: start;
                      padding: 0.1rem 1rem;
                 }
                 p{
                     font-size: 1.2rem;
                     padding: 0.1rem 0.6rem;
                     text-align: start;
                     

                    
                  }
             }
            }
             }
             .mapa{
                 height:38%;
                 width:74%;
                 position: relative;
                 margin:-150px 15% -15% auto;
                 
             
             }
         }
    }
    
    @media (max-width: 442px){
        .contacto{
            margin-top: 16%;
        }
        .login{
            .login-icon{
                font-size: 1.7rem;
            }
        }
        .section1{
        .padre1{
            background-color: #e0742cd1;
            h1{
                font-size: 1.5rem;
                line-height: 1.1;
             
            }
            p{
                color: #fafafa;
                font-family: 'Jost', sans-serif;
                font-size: 0.9rem;
                line-height: 1;
                word-break: break-word;
                word-wrap: break-word;
                font-weight: 400;
                padding: 0.1rem;   
    }
    }
}
.section2{
    h3{
     font-size: 1.6rem;
     margin: auto 38% -80px auto;
    
    }
    .padre3{
     .tel-email{
        width: 90%;
     }
     .mapa{
         height:38%;
         width:90%;
         
         margin:-150px 7% -15% auto;
         
     
     }
 }
}

}
@media (max-width: 360px){
    .section2{
        h3{
         font-size: 1.6rem;
         margin: auto 37% -80px auto;
        
        }
        .padre3{
         .tel-email{
            width: 100%;
            .email{
                p{
                    font-size: 1.2rem;

                }
            }
         }
         .mapa{
             height:38%;
             width:97%;
             
             margin:-150px 8% -15% auto;
             
         
         }
     }
    }
}