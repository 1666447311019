.formularios{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    background-color: #ff9650;
    margin-top: 80px;
  
    
    .section{
        width: 100%;
    }
   
   .section{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        position: relative;
        margin-bottom: 100px;
        h3{
            margin-bottom: 2rem;
            padding-top: 2rem;
            font-size: 1.8rem;
            line-height: 1.5;
            color: #f0efed;
            width: 70%;
            text-align: center;
            text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
        
            strong{
                font-weight:400;
            }
            
        }
    }
    .titulo{
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        padding-top: 2rem;
    
        width:100%;
        color: #f0efed;
        position: relative;
    
        h3{
        font-size: 3rem;
        line-height: 1.1;
        text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
        
        }
    }


   .formu{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-top: 8%;
        position: relative;
    }
   .p1{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 80px;
        a{
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: rgba(20, 20, 20, 0.872);
            margin: 0 20px;
        .iconos{
            font-size: 5rem;
            color: hsla(49, 13%, 32%, 0.829);
            box-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
            border-radius: 10px;
            background-color: #f0efed;

        }
        h4{ font-size: 1.5rem;
            text-align: center;
            padding-top: 0.6rem;
            color: #f0efed;
            font-weight:500;
            text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
            
        }
    }
    }
}
@media (max-width:769px){
  .formularios{ 
.p1{
flex-flow: column wrap;
a{
    margin-top: 20px;
}
}
}
}

@media (max-width:600px){
    .formularios{ 
        .titulo{
           h3{
            font-size: 2.4rem;
            margin-top:10%;
           }
        }
       .formu{
            height: 890px;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            h3{
                margin-bottom: 2rem;
                padding-top: 2rem;
                font-size: 1.4rem;
                line-height: 1.5;
                color: #f0efed;
                width: 90%;
                text-align: center;
                strong{
                    font-weight:400;
                }
                
            }
        }
 .formu{
  margin-top: 3%;
 .p1{
margin-top: 200px;
  }
  }
  }
  }

  @media (max-width:467px){
    .formularios{ 
        margin-top: 40px;
        .titulo{
           h3{
            font-size: 2rem;
            margin-top:10%;
            text-align: center;
            width: 95%;
           }
        }
       .section{
       
            display: flex;
            flex-flow: column wrap;
            align-items: center;
      
            h3{
                
                padding-top: 0.5rem;
                font-size: 1.9rem;
                line-height: 1.5;
                color: #f0efed;
                width: 90%;
                text-align: center;
                margin-bottom: 0px;
                strong{
                    font-weight:400;
                }
                
            }
        }
 .formu{
  margin-top: -40px;
 .p1{

    .iconos{
        font-size: 4rem;
    }
    h4{
        font-size: 1.2rem;
    }
  }
  }
  }
  }
  
