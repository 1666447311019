.footer {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height:110px;
   
  
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-top:-0.4%;
  
    img{
   width: 35px;
  }
  
  .logo-text {
    font-weight: normal;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 5px;
   
  }
}
  
  .creator {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items:center;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    position: relative;
    margin-top: -1.1%;
    margin-bottom: 5px;
  
  .portfolio-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.8rem;
    padding-left: 5px;
    text-shadow: 1.5px 1.5px 3px rgb(0, 0, 0);
  }
}

}
  