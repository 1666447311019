.Materiales{
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin-top: 11%;
    width: 100%;
    background-color:rgba(224, 116, 7, 0.837);
      
  .spinner-overlay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.941);
    z-index: 7;
    top: 0;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .spinner{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      width: 90px;
      height: 90px;
      animation: spin 3.5s linear infinite; 
      border: none;
      img{
        width: 100%;
        height: 100%;
        border-radius: 60px;
  
        border: none;
      }
    }
  }
 
    .grados{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        background-image: url('../../../public/assets/fondo-materiales.png');
        background-size: 100% 100%; /* Hace que la imagen ocupe todo el ancho de la página */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center;
        height: 100vh;
        width: 100%;
        .hijo{
            display: flex;
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items:flex-start;
            margin:2% 0 0 0;
            
        }
        .hijo2{
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items:center;
            margin:0 0 0 20%;

            
        }
      button{
    
            height: 50px;
            font-size: 1.4rem;
            font-weight: bold;
            text-shadow: 2px 2px 5px rgba(63, 63, 63, 0.465);
            border: none;
            background-color: transparent;
            transition: transform 0.4s ease; 

            &:hover{
                transform: scale(1.1);        
  
            }
            a{  
                border-bottom: 3px solid rgba(240, 248, 255, 0.932);

                color: rgba(240, 248, 255, 0.955);
                text-decoration: none;
                list-style-type: none; 
            }
    }
}
}

@media (min-width:1030px){
    .Materiales{
        margin-top: 12%;
    .grados{
        
        background-size: 73% 100%; 
        .hijo2{
            margin-top: -1%;
        }
      
    }
}
}


@media (max-width:600px){
    .Materiales{
        margin-top: 16%;
    .grados{
        background-size: 100% 100%; /* Hace que la imagen ocupe todo el ancho de la página */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center;
        .hijo2{
            margin-top: -6%;
        }
    }
}
}


@media (max-width:600px){
    .Materiales{
        margin-top: 16%;
    .grados{
        background-size: 100% 100%; /* Hace que la imagen ocupe todo el ancho de la página */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center;
        .hijo2{
            margin-top: -6%;
        }
    }
}
}

@media (max-width:500px){
    .Materiales{
        margin-top: 19%;
    .grados{
        background-image: url('../../../public/assets/fondo-materiales1.png');
        background-size: 100% 100%; /* Hace que la imagen ocupe todo el ancho de la página */
        background-repeat: no-repeat; /* Evita que la imagen se repita */
        background-position: center;
        button{
            font-size: 1.2rem;
        }
        .hijo2{
            margin-top: -9%;
        }
    }
}
}