.fotos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 640px;
    margin-left: -3%;
    margin-bottom: 50px;
   z-index: 1;
    img{
        width: 100%;
        height: 100%;
    }
   
}

@media (max-width:768px){
    .fotos{
      width: 350px;
        margin-left:0 ;
        img{
            width: 100%;
            height: 100%;
        }
       
        
    }
}

@media (max-width:420px){
    .fotos{
        width: 90%;
   
        margin-left: -6%;
       
        padding-left: 0.8rem;  
        img{
            width: 100%;
      height: 200px;
     
        }
        
    }
}

