*{
  margin: 0;
  padding: 0;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}  
.linea{
  color: rgb(0, 0, 0);
  text-decoration: none;
  list-style-type: none; 
 }

