
.logo{
    width: 70px;
    height: 64px;
    padding: 1rem 1.5rem;
}
.menuItem{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width:100vw;
    height: 270px;
 
   
    .boton{
        color: black;
    }
}
.cotenedor{
 
    box-shadow: 1px 2px 2px 5px rgba(0, 0, 0, 0.157);
   color: black;
   background-color:rgb(255, 255, 255);
   position: fixed;
   margin-bottom: 10px;
   z-index: 3;
   
   &:hover{
   .logo{
    width: 74px;
    height: 66px;
    padding: 1rem 1.6rem;
   transition: all 0.45s ease;
   transition-duration: 0.5s;
   transition-timing-function: ease;
   transition-delay: 0.1s;
   transition-property: all;
  
}
} 
}

.redes{
    display: flex;
    flex-flow: row wrap;
    position: fixed;
    margin-left:80%;
a{
    padding-right: 1rem;
.face{
    color: rgb(0, 149, 255); 
}
.wpp{
    color: rgb(19, 174, 86);
}
}
}
.redes2{
    margin-left: -12%;
    a{   
        padding: 0.6em 1rem;
        .face{
            font-size: 1.9rem;
            color: rgb(0, 149, 255);
        }
        .wpp{
            font-size: 1.9rem;
            color: rgb(19, 174, 86);
        }
    }
}

@media (max-width:899px){
    .cotenedor{
   
        .logo{
            width: 60px;
            height: 57px;
            margin-left: 15%;
        }
        h4{

            font-size: 1.5rem;
            margin-left: 2%;
            width: 35%;
        }
       position: fixed;
       &:hover{
       .logo{
        width: 70px;
        height: 64px;
        padding: 1rem 1rem;
       transition: all 0.45s ease;
       transition-duration: 0.45s;
       transition-timing-function: ease;
       transition-delay: 0.2s;
       transition-property: all;
    
    }
    } 
    }
    .redes{
      display: none;
    a{
        padding-right: 0.8rem;
    .face{
        color: rgb(0, 149, 255);
     
        
    }
    .wpp{
        color: rgb(19, 174, 86);
    }
    }
    }
}

@media (max-width:596px){

    .cotenedor{
        
        .logo{
            width: 50px;
            height: 47px;
            margin-left: 8%;
        }
        h4{
            font-size: 1rem;
            margin-left: 2%;
            width: 35%;
        }
       &:hover{
       .logo{
        width: 60px;
        height: 54px;
        padding: 1rem 0.6rem;
    
    }
    } 
    }
    
}
@media (max-width:400px){
 
       .cotenedor{
        .logo{
            width: 40px;
            height: 37px;
            margin-left: 8%;
        }
        h4{
            font-size: 0.8rem;
        
            width: 35%;
        }
       &:hover{
       .logo{
        width: 50px;
        height: 44px;
        padding: 1rem 0.5rem;
    
    }
    } 
    }
}
@media (max-width:355px) {
   
       .cotenedor{
        .logo{
            width: 40px;
            height: 37px;
            margin-left:0;
        }
        h4{
            font-size: 0.8rem;
        
            width: 35%;
        }
        &:hover{
            .logo{
                margin-left: 4%;

         
         }
         } 
    }
    
}

