.novedades{
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
    margin-top: 12%;
    width: 100%;
  height: 100%;
  background-position:center;

  .grados{
   display: flex;
   margin: 45px 0;

   select{
      width: 140px;
      height: 40px;
      font-size: 1rem ;
      margin: 0 8px;
      
      cursor: pointer;
      box-shadow: 0.5px 1px 1.5px 2px rgba(21, 21, 21, 0.272);
      border-radius: 6px;
      border: 1px solid rgb(255, 128, 0);
      background-color: rgb(255, 255, 255);
      option{
         text-align: center;
      }

   }
   .linea{
      margin-bottom: 15px;
   }
  }
  
  .spinner-overlay{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
   position: fixed;
   background-color: rgba(255, 255, 255, 0.941);
   z-index: 7;
   top: 0;
   @keyframes spin {
     0% {
       transform: rotate(0deg);
     }
     100% {
       transform: rotate(360deg);
     }
   }
   .spinner{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 60px;
     width: 90px;
     height: 90px;
     animation: spin 3.5s linear infinite; 
     border: none;
     img{
       width: 100%;
       height: 100%;
       border-radius: 60px;
 
       border: none;
     }
   }
 }

.uniformes{
   margin-top: 2%;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content:center;
   align-items: center;
   width: 70%;

   .libros{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 30px;
      width: 70%;
      height: 250px;
      margin-bottom: 70px;
      box-shadow: 1px 2px 2px 3px rgba(76, 75, 75, 0.272);
      background-image: url('../../../public/assets/librito.png');
      background-repeat:no-repeat ;
      background-size: 100% 100%;
 .titulo-libro{
   color: rgb(0, 0, 0);
   animation: none;
   box-shadow: none;
   text-shadow: 2px 2px 5px rgba(71, 71, 71, 0.016);
   margin-top: -60px;
   letter-spacing: 1.5px;

 }
 
 .btn-libros{
   display: flex;
   flex-direction: row nowrap;
   margin-top: 20px;
   button{ 
     letter-spacing: 0.5px;
     background-color: rgb(255, 255, 255);
      padding: 0 1rem;
      height: 60px;
      font-size: 1.1rem;
      border: 1px solid rgb(247, 126, 13);
      border-radius: 8px;
      margin: 20px 40px;
      box-shadow: 1px 2px 2px 2px rgba(3, 3, 3, 0.272);
      transition: transform 0.4s ease; 
      &:hover{
          transform: scale(1.1);        
         text-decoration: underline;
      }
      
   }
 }
   }
   p{
      letter-spacing: 1.5px;
      color: rgb(0, 0, 0);
      margin: 10% 0;
      text-shadow: 2px 2px 5px rgba(71, 71, 71, 0.016);
      font-weight: bold;
      font-size: 1.5rem;
 
   }
   .btn-uniformes{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15%;
      gap:20px;
    a{
      text-align: center;
      padding:0 0.2rem;
      padding-top: 0.5rem;
      color: rgb(0, 0, 0);
       font-size: 1.1rem;
  
       list-style-type: none; 
       letter-spacing: 0.5px;
       transition: transform 0.4s ease; 
       text-decoration: none;
       box-shadow: 1px 2px 2px 2px rgba(107, 107, 107, 0.478);
       height: 30px;
       width: 150px;
       border-radius: 5px;
       background-color: rgb(247, 248, 249);
       text-shadow: 2px 2px 5px rgba(71, 71, 71, 0.016);
       border: 1px solid rgb(242, 107, 17);
  
       &:hover{
           transform: scale(1.1);        
            text-decoration: underline;
       }

    }

   }
  
}

.descripcion{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 1%;
   border-radius: 5px;
   box-shadow: 1px 2px 2px 5px rgba(0, 0, 0, 0.157);
   
   width: 50%;
   span{
      display: none;
   }
   img{
      width: 100%;
      height:100%;
   
      margin-top: 10px;
   }
   h2{
      margin: 10px 0;
   }
p{
   white-space: pre-wrap;
   font-size: 1rem;
   color: rgb(0, 0, 0);
   padding: 1rem;
   letter-spacing: 0.6px;
   text-shadow: 1px 1px 1px rgba(35, 34, 34, 0.03);
   line-height: 1.3;
   margin-bottom: 20px;
}
}



 }

 .libros-aula{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 15%;
   img{
      width: 60%;
      height: 100%;
      padding: 1rem 0;
   }
  }
.libros-ingles{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 15%;
   img{
      width: 60%;
      height: 100%;
      padding: 1rem 0;
   }
  
}

@media (max-width:779px){
   .novedades{
      height: 100%;
      margin-top: 17%;
      .uniformes{
         width: 100%;
         .libros{
            width: 90%;
         }
      }
      h2{
         padding-top: 1rem;
      }
      .padre{
         width: 100%;
         flex-direction: column;
         justify-content: center;
         align-items: center;
     
         .calendario{
            width: 60%;
            height: 100%;
            text-align: center;
         }
      }
      .descripcion{
         width: 95%;
      }
   }
   
}
    
@media (max-width:610px){
   .libros-aula{
      margin-top: 20%;
      img{
         width: 100%;
      }
   }
   .libros-ingles{
      margin-top: 20%;
      img{
         width: 100%;
      } 
   }
   .novedades{
      height: 100%;
    .uniformes{
     
      .btn-uniformes{
         font-size: 1.2rem;
         flex-direction: column;
      }
      .libros{
     margin-bottom: 120px;
         .btn-libros{
            button{
            margin:0 20px;
         }
         }
      }
    }
      .padre{
         flex-direction: column;
         justify-content: center;
         align-items: center;
        
   
         .calendario{
            text-shadow: 4px 4px 7px rgba(3, 3, 3, 0.641);
            h3{
               padding: 20px 10px;
            }
            width: 60%;
            height: 100%;
            text-align: center;
    
            p{
               font-size: 1rem;
            }
         }
        
      }
   }
   
}

@media (max-width:500px){
   .novedades{
      height: 100%;
      margin-top: 20%;

      .grados{
         flex-direction: column;
         select{
            width: 175px;
            font-size: 1.1rem;
            margin: 5px 0;
         }
       }
      .img-inscripcion{
         width: 100%;
     
         img{
          width: 100%;
            border: none;
      }
   }
      .uniformes{
         h1{
            margin: 17% 0;
            padding: 2rem 2rem;
         }
       
         }
         .btn-uniformes{
            flex-direction: column;
            margin-bottom: 15%;
            
            a{
               padding: 1rem 0;

            }
         }

         .libros{
            width: 95%;
            .titulo-libro{
           
                margin-bottom: -10px;
               font-size: 1.7rem;
              }

            .btn-libros{
        
               button{
               margin:0 10px;
        
            }
            }
         }

      }

      .padre{
         flex-direction: column;
         justify-content: center;
         align-items: center;

        
   
         .calendario{
            margin-top: 2%;
            text-shadow: 4px 4px 7px rgba(3, 3, 3, 0.641);
            h3{
               margin-left: -8%;
               padding: 20px 10px;
               font-size: 1.5rem;
               strong{
                  font-size: 1.8rem;
               }
            }
            width: 60%;
            height: 100%;
            text-align: center;
    
            p{
               font-size: 1.1rem;
               padding: 4px;
            }
         }
        
      }
  
   
}
@media (max-width:390px){
   .novedades{

   .uniformes{
   .libros{
      width: 95%;
      .titulo-libro{
         margin-top: -30px;
         font-size: 1.7rem;
        }
      .btn-libros{
     
         button{
         margin:0 10px;
         font-size: 1rem;
      }
      }
   }
}
}
}
